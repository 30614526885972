import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Full Mouth Reconstruction – Best Dental Implants in Toronto" 
    description="If severe dental problems are causing you discomfort you may be a good candidate for a Full Mouth Reconstruction.  Book your Free Consultation at The Smile Stylist today." 
    keywords="dental implants, porcelain veneers, best dental implants Toronto" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="reconstruction hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Full Mouth</h1>
            <h2>Reconstruction</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p className="lead">If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.</p>
            <p>As well-qualified dental with almost 20 years of experience, we can combine the aesthetics of cosmetic dentistry with the science of neuromuscular dentistry to perform a full mouth reconstruction. Orthodontic appliances can be used to help properly position your jaw. Once the jaw is properly aligned, relieving some of the pain caused by the TMJ syndrome, cosmetic and restorative dentistry procedures (porcelain veneers, dental crowns, bridgework, dental implants, and onlays) are completed. The result is an attractive, pain-free new “Smile Stylist” smile.!</p>
            <h2>Severe Dental Problems – Repairing Worn Teeth</h2>
            <p>Dental problems such as damaged and worn teeth not only detract from your mouth visually, but they also can affect the alignment of your jaws and your chewing ability. The procedures used by Smile Stylist dentist when repairing worn teeth will depend on the extent of your teeth’s damage. Generally a crown can be placed over your damaged tooth to strengthen and reinforce the tooth. If there is a gap between your natural teeth, a dental bridge may be used to cover the gap. If you no longer have a natural tooth to which your dentist can secure a crown, a dental implant may be placed in your jaw to create the crown’s foundation.</p>
            <h2>TMJ Syndrome</h2>
            <p>TMJ syndrome is a disorder that affects your jaw’s temporomandibular joint, causing pain in your head and neck and a possible popping of your jaw when you chew. Most cases of TMJ syndrome are temporary and can be treated effectively with bite splints, muscle relaxants, compresses, and jaw exercises. If your dental problems persist, we will give you a more advanced treatment regime above and beyond the home-care treatment. Even in advanced cases of degenerative joint disease, we can perform more comprehensive procedures to relieve the discomfort.</p>
            <h2>Full Mouth Reconstruction vs. Smile Makeover – Which to Choose?</h2>
            <p>
              So how does full mouth reconstruction differ from smile makeover? <a href="/before-after">A smile makeover</a> is something that you elect to have performed, while a full mouth reconstruction is something that you need.
            </p>

            <p>
              As a premier cosmetic dental office, we pride ourselves in being on the forefront of responding to the increasing consumer demands for beautiful, natural-looking dentistry. However, for some patients, it is becoming hard to draw a line between purely “cosmetic” (such as elective) dentistry and “restorative”   (necessary)  dentistry. We believe that no full mouth rehabilitation is complete without the patient’s aesthetic goals achieved! For example, it is now possible for us to treat tooth decay with a durable tooth-like filling material that looks natural.
            </p>

            <p>
              If you need full mouth reconstruction, the materials and techniques we utilize in our practice today make it possible for us to provide you with durable, functional and clinically sound treatments that also delivers that signature “Smile Stylist” smile.
            </p>

            <p>It is also important to note that a smile makeover – though performed primarily to improve the aesthetic appearance of the smile – requires the use of clinically proven dental materials and treatment techniques, as well as exceptional knowledge, training and skill on the part of the dentist. Many of the same techniques and equipment used for full mouth reconstruction are also used to ensure the success and long-term stability of smile makeover treatments.</p>
            <h3>If you are interested in learning more about this service or desire to improve your smile then contact The Smile Stylist today!</h3>
            <p><a className="btn btn-red" href="/contact">Book Your Appointment</a></p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center service__shadow-imgs" data-wow-delay="0.6s">
            <p>
              <img src={ require("../images/desktop/services/Full Mouth Reconstruction.png" )} alt="Full Mouth Reconstruction"  />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Full Mouth Reconstruction 2.png" )} alt="Before and After Teeth Reconstruction "  />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Full-restore3.jpg" )} alt="Worn Teeth Reconstruction Before and After "  />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Full-restore1.jpg")} alt="Damaged Teeth Reconstruction Before and After "  />
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage